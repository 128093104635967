import $ from "jquery";
import '../../assets/libs/bootstrap-5.0.2-dist/js/bootstrap.bundle.min.js';
import * as AOS from '../../assets/libs/aos';
import Footer from '@/components/footer/Footer';
import Header from '@/components/header/header';
import Breadcrumb from '@/components/breadcrumb/breadcrumb';
import Swiper from 'swiper/swiper-bundle.min.js';
import 'swiper/swiper-bundle.min.css';

export default {
    name: "Solar",
    data() {
        return {
            imageSource: require('@/assets/img/build-color/building-img-color1.png'),
            lists_color: [
                {id: 1, name_color:"gray-dark",img: require('@/assets/img/Home/example_glass_01.png')},
                {id: 2, name_color:"gray-normal",img: require('@/assets/img/Home/example_glass_02.png')},
                {id: 3, name_color:"gray-light",img: require('@/assets/img/Home/example_glass_03.png')},
                {id: 4, name_color:"white-dark",img: require('@/assets/img/Home/example_glass_01.png')},
                {id: 5, name_color:"white-normal",img: require('@/assets/img/Home/example_glass_01.png')},
                {id: 6, name_color:"sky-light",img: require('@/assets/img/Home/example_glass_01.png')},
                {id: 7, name_color:"green-lime",img: require('@/assets/img/Home/example_glass_01.png')},
                {id: 8, name_color:"sky-normal",img: require('@/assets/img/Home/example_glass_01.png')},
                {id: 9, name_color:"orange-light",img: require('@/assets/img/Home/example_glass_01.png')},
                {id: 10, name_color:"green-light",img: require('@/assets/img/Home/example_glass_01.png')},
                {id: 11, name_color:"yellow-light",img: require('@/assets/img/Home/example_glass_01.png')},
                {id: 12, name_color:"orange-normal",img: require('@/assets/img/Home/example_glass_01.png')},
                {id: 13, name_color:"purple-normal",img: require('@/assets/img/Home/example_glass_01.png')},
                {id: 14, name_color:"black-normal",img: require('@/assets/img/Home/example_glass_01.png')},
                {id: 15, name_color:"snow",img: require('@/assets/img/Home/example_glass_01.png')},
            ],
            current_color: {
                id: 1,
                name_color:"gray-dark",
                img: require('@/assets/img/Home/example_glass_01.png')
            },
        }
    },
    methods: {
        gotoPage(url){
            location.href = url;
        },
    },
    watch: {
        async current_color() {
            $("#shade-image-preview").removeClass("aos-animate");
            setTimeout(function () {
            $("#shade-image-preview").addClass("aos-animate");
            }, 200);
        },
    },
    mounted() {
        var swiper = new Swiper(".swiper", {
            loop: true,
            slidesPerView: 1,
            spaceBetween: 0,
            speed: 4000,
            pauseOnMouseEnter: false,
            parallax: true,
            autoplay: {
                delay: 1000,
                disableOnInteraction: false
            },
            breakpoints: {
                768: {
                    loop: true,
                    slidesPerView: 2,
                    spaceBetween: 20
                }
            },
        });
        document.title = "AGC - Solar";
        AOS.init();
        resize();
        function resize() {
            if (window.innerWidth < 800) $('.footer').css({ 'position': 'relative', 'top': '0px' });
        }
        // eslint-disable-next-line
        $('.m-search-BT').on('click', (evt) => {
            $('.m-search-BT + .dp').toggle()
        });
        $('img[alt="color"]').on('click', (evt) => {
            $('img[alt="color"].active').removeClass("active");
            $('#' + evt.target.id).addClass("active");
            // $('img[alt="building-img"]').attr('src',`/img/build-color/building-img-${evt.target.id}.png`); 
            this.imageSource = require(`@/assets/img/build-color/building-img-${evt.target.id}.png`)
        })
    },
    components: {
        Footer, Header, Breadcrumb
    }
};